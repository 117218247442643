﻿import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  // tslint:disable-next-line
  selector: 'app-main',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  title = 'TT';

  //PROD BETA
  //static API_URL="http://www.itbeta2.com.br:8081";
  // static API_URL="https://tt-homol-api.itbeta2.com.br";
  //PROD KN
  //static API_URL="http://10.5.32.121:8081";
  static API_URL="https://tt-api.itbeta2.com.br";

  // LOCAL
  // static API_URL = 'http://localhost:8080';


  //HOMOl
  //static API_URL = 'http://191.252.219.245:9001'

  //static API_URL="http://192.168.0.11:8080";
  // static API_URL="http://192.168.0.10:8080"
  //static API_URL="http://192.168.0.107:8080";
  //static API_URL="http://192.168.0.104:8080";
  //static API_URL="http://192.168.0.121:8080";


  constructor(private router: Router, private titleService: Title,
    @Inject(DOCUMENT) private _document: HTMLDocument) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
      if (AppComponent.API_URL == 'http://10.5.32.121:8081') 
      {
        this.titleService.setTitle('IS Promise to Delivery');
      }
      else {
        this.titleService.setTitle('itBeta² Sistemas');
        this._document.getElementById('appFavicon').setAttribute('href', '../assets/b2.png');
      }
    });
  }
}
